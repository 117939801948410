import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { Link } from "gatsby";

const Footer = ({ domain, pages, email }) => {
  const [mail, setEmail] = useState("");
  const [result, setResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const resultValue = await addToMailchimp(mail);
    setResult({ result: resultValue });
    setEmail("");
    toast(resultValue?.msg);
  };

  return (
    <footer className="footer">
      <div className="container is-max-widescreen">
        <div className="columns pt-5 m-0 p-4">
          <div className="column has-text-centered">
            <Link to={`/`}>
              <img style={{ maxWidth: "100px" }} src={domain?.logo_light} className="foot-brand" />
            </Link>
            <p>{domain?.description}</p>
            <p>
              Contact us: <a href={`mailto:${email}`}>{email}</a>
            </p>
          </div>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          <div class="field is-grouped">
            <p class="control is-expanded">
              <input
                id="outlined-email-input"
                label="Email"
                type="email"
                name="email"
                autoComplete="email"
                variant="outlined"
                value={mail}
                className="required email input"
                placeholder="Your email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </p>
            <p class="control">
              <button class="button is-info mt-0" type="submit">
                Subscribe
              </button>
            </p>
            <ToastContainer />
          </div>
        </form>
      </div>
      {/* <div className="column has-text-centered">
        <p>
          ofmum is owned by{" "}
          <a style={{ color: "#3e8ed0", textDecoration: "none" }} href="https://ideaclan.com/">
            Idea Clan
          </a>
        </p>
      </div> */}
      <div className="content has-text-centered is-max-widescreen p-3 mt-6 copyright">
        <div className="container is-max-widescreen">
          <div className="columns">
            <div className="column has-text-left-tablet bd-b">
              <p>
                We earn a commission from partner links on Our Website. Commissions do not affect
                our editors' opinions or evaluations.
              </p>
            </div>
            <div className="column">
              <div className="footer-menu has-text-right-tablet">
                {/* {<pre style={{ textAlign: "left" }}>{JSON.stringify({ pages, rrr: pages }, null, 2)}</pre>} */}
                {pages?.map((pageData) => {
                  return (
                    <Link to={`/${pageData.slug}/`} className="footer-item">
                      {pageData?.title ? pageData?.title : pageData?.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
